<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>
<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}
* {
  padding: 0;
  margin: 0;
}
</style>
